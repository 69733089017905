import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';
import { getLogout, getProfile } from '../store/slices/user';
import { getNotice } from '../store/slices/ilharu';
import { getEvents } from '../store/slices/schedule';
import { btnMent } from '../utils/listData';
import { isEmpty } from 'lodash';
import {
  NavyButton,
  BlueButton,
  MainButton,
  BorderBottom
} from '../component/Styled';
import ImgComponent from '../component/ImgComponent';
// 이미지
import calendar from '../asset/img/ic/calendar.webp';
import bell from '../asset/img/ic/bell.webp';
import activityHistory from '../asset/img/ic/activityHistory.webp';
import whiteArrow from '../asset/img/ic/whiteArrow.svg';
import blackArrow from '../asset/img/ic/blackArrow.svg';

import level1 from '../asset/movie/level1.mp4';
import level2 from '../asset/movie/level2.mp4';
import level3 from '../asset/movie/level3.mp4';
import level4 from '../asset/movie/level4.mp4';

import medal1 from '../asset/img/1.svg';
import medal2 from '../asset/img/2.svg';
import medal3 from '../asset/img/3.svg';
import medal4 from '../asset/img/complete.svg';
import Loading from '../component/Loading';
import { persistor } from '../store';

export const Layout = styled.div`
  max-width: 450px;
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  position: relative;
`;

const Box = styled.div`
  width: 100%;
  border-radius: 10px;
  border: 2px solid var(--brand-color-sub-3, #97b7f6);
  background: var(--gray-color-gray-0, #fff);
  box-shadow: 0px 0px 10px 0px rgba(202, 208, 211, 0.3);
  padding: 20px 16px;
`;

const Main = ({ isLoading }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { notices } = useSelector((state) => state.ilharu);
  const { myProfile } = useSelector((state) => state.user);

  const noticeList = notices.filter((notice) => {
    if (notice?.type === 'notice') {
      const showNotice = notice?.isAdminPriority || notice?.isFavorite;
      return showNotice ? true : notice?.isRead === false;
    } else if (notice?.type === 'survey') {
      return notice?.isComplete === false;
    } else {
      return false;
    }
  });

  useEffect(() => {
    dispatch(getNotice());
    dispatch(getProfile());
    dispatch(getEvents());

    localStorage.setItem('cate', 'all');
    localStorage.setItem('pageNumber', 1);
    window.addEventListener('beforeunload', function (event) {
      // 탭이 닫히기 전에 수행할 작업
      localStorage.removeItem('hasVisited');
    });
    // 컴포넌트가 언마운트될 때 타이머를 정리
    return () => {
      // clearTimeout(timer);
    };
  }, []);

  const handleNavigate = (notice) => {
    if (notice?.type === 'notice') {
      navigate(`/noticeDetail/${notice?._id}`, { state: notice });
    } else {
      navigate(`/surveyDetail/${notice?._id}`, { state: notice });
    }
  };

  const handleLogout = async () => {
    dispatch(getLogout());
    const purge = async () => {
      await persistor.purge();
    };
    await setTimeout(() => purge(), 200);
  };

  const levelImages = [level1, level2, level3, level4];
  const medalImages = [medal1, medal2, medal3, medal4];
  const levelTexts = [
    '멋쟁이 모냐',
    '진짜 멋쟁이 모냐',
    '정말 멋쟁이 모냐',
    '제일 멋쟁이 모냐'
  ];

  const handleBackImg = (level) => levelImages[level - 1];
  const handleImg = (level) => medalImages[level - 1] || medalImages[3];
  const handleText = (level) => levelTexts[level - 1] || levelTexts[3];

  return (
    <Layout>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className="h-auto relative mb-4">
            <video muted autoPlay loop style={{ width: '100%' }}>
              <source src={handleBackImg(myProfile?.level)} type="video/mp4" />
            </video>
            <div className="absolute top-4 left-[10px]">
              <NavyButton className="mt-8 text-14 yg">
                <div className="mx-auto">
                  <img src={handleImg(myProfile?.level)} alt="레벨이미지" />
                </div>
                {/* {handleText(myProfile?.level)}
                 */}
                {myProfile?.nickname}
              </NavyButton>
              <BlueButton
                className="flex font-bold mt-2"
                onClick={() => navigate('/metaverse')}>
                <div className="flex items-stretch">
                  <div>메타버스 입장하기</div>
                  <ImgComponent src={whiteArrow} />
                </div>
              </BlueButton>
            </div>
          </div>

          <div className="w-324 mx-auto my-0">
            <div className="flex justify-between font-medium text-text1">
              <MainButton
                className="w-[160px] h-[79px] tracking-[-0.21px]"
                onClick={() => navigate('/scheduler')}>
                <div>
                  <img src={calendar} alt="캘린더" />
                </div>
                일정관리
              </MainButton>
              <MainButton
                className="w-[160px] h-[79px] tracking-[-0.21px]"
                onClick={() => navigate('/activityHistory')}>
                <div>
                  <img src={activityHistory} alt="캘린더" />
                </div>
                활동내역
              </MainButton>
            </div>
            <Box className="mt-4">
              <div className="flex justify-between mb-3">
                <div className="flex gap-1.5 text-text1">
                  <img src={bell} alt="벨" />
                  일하루 소식알림
                </div>
                <div
                  className="text-text5 cursor-pointer"
                  onClick={() => navigate('/notice')}>
                  더 보기
                </div>
              </div>
              {/* 안읽은 것만 출력 */}
              {isEmpty(noticeList) ? (
                <div className="text-center">소식이 없습니다.</div>
              ) : (
                noticeList.map((notice, idx) => {
                  return (
                    <BorderBottom
                      key={idx}
                      onClick={() => handleNavigate(notice)}>
                      <div className="text-text1">{notice?.title}</div>
                      <ImgComponent src={blackArrow} />
                    </BorderBottom>
                  );
                })
              )}
            </Box>
            <div className="grid grid-cols-2 mt-4 text-14  font-medium text-text3 gap-[8px]">
              {btnMent.map((el, idx) => (
                <MainButton
                  key={idx}
                  className="flex-col w-[160px] h-[56px] tracking-[-0.21px]"
                  onClick={() => navigate(el.address)}>
                  <div className="flex items-center w-[115px]">
                    <div className="mr-4">
                      <img src={el.img} alt="이미지" />
                    </div>
                    <div className="whitespace-pre-wrap text-center">
                      {el.ment}
                    </div>
                  </div>
                </MainButton>
              ))}
            </div>
            <div>
              <div className="flex justify-end mt-8 text-14 gap-[5px]">
                <div
                  className="text-text7 cursor-pointer"
                  onClick={() => navigate('/myPage')}>
                  정보수정
                </div>
                <div className="text-text7">|</div>
                <div
                  className="text-text7 cursor-pointer"
                  onClick={handleLogout}>
                  로그아웃
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </Layout>
  );
};

export default Main;
